<template>
  <div>
    <v-btn class="ma-3" color="flatButton" text to="/new-tier">
      <v-icon left dark>mdi-plus</v-icon>New Tier
    </v-btn>
    <v-data-table
      :loading="loading"
      loading-text="Loading... Please wait"
      :headers="headers"
      :items="tiers"
      :search="search"
    >
      <template v-slot:item.name="{ item }">
        <v-btn color="flatButton" text :to="`/tiers/${item.id}`">{{ item.name }}</v-btn>
      </template>
    </v-data-table>
  </div>
</template>
<script>
export default {
  data() {
    return {
      loading: false,
      search: null,
      tiers: [],
      headers: [
        { text: "Name", value: "name" },
        { text: "Total Outlets", value: "outlets.length" },
        { text: "Description", value: "descript" },
      ],
    };
  },
  methods: {
    getTiers() {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `/tiers`)
        .then((res) => {
          console.log(res, "r");
          self.tiers = res;
          self.loading = false;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
  },
  created() {
    this.getTiers();
  },
};
</script>